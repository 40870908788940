import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { FiCheck } from 'react-icons/fi';
import image from '../img/malen.jpg';
import styled from 'styled-components';
import TextAndImagePage from '../components/TextAndImagePage';

const QualificationPage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;
  const { qualifications, training } = post.frontmatter;

  return (
    <Layout>
      <TextAndImagePage
        title={post.frontmatter.title}
        image={image}
        backLink="/"
        backLinkText="Zurück zur Startseite"
        content={
          <>
            <List>
              {qualifications.map((qualification) => (
                <Item>
                  <IconContainer>
                    <CheckIcon />
                  </IconContainer>
                  <span style={{ fontSize: 17 }}>{qualification.text}</span>
                </Item>
              ))}
            </List>
            <div style={{ marginTop: 20 }}>{training.title}</div>
            <List>
              {training.items.map(({ text }) => (
                <Item>
                  <IconContainer>
                    <CheckIcon />
                  </IconContainer>
                  <span style={{ fontSize: 17 }}>{text}</span>
                </Item>
              ))}
            </List>
          </>
        }
      />
    </Layout>
  );
};

export default QualificationPage;

export const query = graphql`
  query QualificationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        qualifications {
          text
        }
        training {
          title
          items {
            text
          }
        }
      }
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
`;

const IconContainer = styled.div`
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 23px;
`;

const CheckIcon = styled(FiCheck)`
  color: hsl(100, 90%, 40%);
`;

const List = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;
